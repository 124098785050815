export const colors = {
	brand1: "#EEF8D2",
	brand2: "#DCF1A5",
	brand3: "#CBEB77",
	brand4: "#B9E44A",
	brand: "#C9F94B",
	brand6: "#86B117",
	brand7: "#658511",
	brand8: "#43580C",
	brand9: "#222C06",
	gray1: "#CCCCCF",
	gray2: "#AEAEB2",
	gray3: "#8F9195",
	gray4: "#717378",
	gray: "#53555B",
	gray6: "#424449",
	gray7: "#323337",
	gray8: "#212224",
	gray9: "#111112",
	white: "#FFFFFF",
	black: "#000000",
	blue1: "#DDEBF8",
	blue2: "#BBD7F1",
	blue3: "#99C3E9",
	blue4: "#77AFE2",
	blue: "#559BDB",
	blue6: "#447DB1",
	blue7: "#335F86",
	blue8: "#23405C",
	blue9: "#122231",
	red2: "#F1BBBB",
	red3: "#e78f8f",
	red4: "#E27777",
	red: "#DB5555",
	red6: "#B14444",
	red7: "#863333",
	red8: "#5C2323",
	red9: "#311212",
	yellow1: "#FBF1DE",
	yellow2: "#F7E3BC",
	yellow3: "#F3D59B",
	yellow4: "#EFC779",
	yellow: "#EBB958",
	yellow6: "#C19747",
	yellow7: "#977636",
	yellow8: "#6C5425",
	yellow9: "#423314",
	green1: "#CFF8E4",
	green2: "#9FF1C9",
	green3: "#6EEAAF",
	green4: "#3EE394",
	green: "#0EDC79",
	green6: "#0BB061",
	green7: "#088449",
	green8: "#065830",
	green9: "#032C18",
};

export const defaultBorderRadius = "1px";
